/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
* {
  font-family: "Poppins";
}

.min-w-content {
  min-width: fit-content;
}

.min-w-max-content {
  min-width: max-content;
}

.card-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.my-card {
  @apply bg-white card-shadow px-6 py-3 my-3;
}

button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.error-snackbar {
  @apply bg-red-300 text-red-800;
}

h1 {
  @apply text-3xl font-bold;
}

.family-inter {
  font-family: inter;
}

.family-poppins {
  font-family: "Poppins";
}

.break-word {
  word-wrap: break-word;
}

.fw-400 {
  font-weight: 400;
}

.theme-default .light .mat-button-toggle-checked,
.theme-default.light .mat-button-toggle-checked {
  background-color: black;
}
.theme-default .light .mat-button-toggle-checked .mat-button-toggle-label-content,
.theme-default.light .mat-button-toggle-checked .mat-button-toggle-label-content {
  color: white !important;
}